.products-container {
    .about-hero-txt {
        padding: 100px 0 0 0;
    }
}

.section-height {
    min-height: 100vh;
}

.sp-box {
    background-color: #F4F4F4;
    min-height: 225px;
    h6 {color: #4B4B4B;}
    p {font-size: 16px;}
    .card-title {font-size: 20px;margin: 20px 0;}
    .card-body {padding: 25px;}
}

.products-types {
    .btn-primary {font-size: 15px;}
}

@media (max-width:767px) {
    .products-container .about-hero-txt {
        padding: 30px 0 0 0;
    }
    .products-types .btn-primary {
        font-size: 14px;
    }
}