.p-details-nav {
    margin-bottom: 50px;
    .btn-primary {
        width: 100%;
        background-color: #007583;
        border-color: #007583;
        color: rgba(255 255 255 / 80%);
        height: 44px;
        line-height: 30px;
        text-transform: uppercase;
        font-size: 14px;
        &:hover, &.active {
            background-color: #3BB3C1;
            border-color: #3BB3C1;
        }
    }
}

.details-img {
    margin-bottom: -40px;
}

.products-details-sec {
    h2 {font-size: 40px;}
}

.card-no-n {
    background-color: #9ED983;
    width: 45px;
    height: 35px;
    color: #fff;
    font-size: 13px;
    font-weight: 700;
    text-align: center;
    line-height: 35px;
    margin-bottom: 15px;
    border-radius: 20px;
}

.no-icon-c {
    // background-color:rgba(158 217 131 / 28%);
    width: 70px;
    // height: 46px;
    // color: #9ED983;
    font-size: 26px;
    font-weight: 700;
    text-align: center;
    // line-height: 42px;
    margin-bottom: 20px;
    // border-radius: 20px;
    // svg {
    //     stroke-width: .5px;
    // }
}

.casestudy-box {
    .btn-outline-primary {
        color: var(--secondary-color);
        &:hover {color: var(--base-color);}
    }
    h6 {
        color: rgba(102 102 102 / 67%);
    }

    .casestudy-image {
        border-radius: 20px;
        overflow: hidden;
        height: 240px;
        margin-bottom: 20px;
        img {width: 100%;}
    }
}

.footerpro-links {
    background-color: var(--dark-color);
    padding: 70px 0 30px 0;
    .p-details-nav {
        margin-bottom: 0;
    }
}

.details-slide {
    p {font-size: 18px;}
    h2 {font-weight: 500;}
}

@media (max-width:1023px) {
    .p-details-nav {
        margin-bottom: 0px;
        .btn-primary {
            margin-top: 9px;
        }
    }

    .products-details-sec {
        h2 {font-size: 30px;}
    }
}


@media (max-width:767px) {
    .p-details-nav {
        margin-bottom: 0px;
        .btn-primary {
            height: 35px;
            line-height: 22px;
            text-transform: uppercase;
            font-size: 11px;
            margin-top: 9px;
        }
    }

    .p-details-nav .row {
        margin-left: -3px;
        margin-right: -3px;
        .col-4 {
            padding-left: 3px;
            padding-right: 3px;
        }
    }

    .products-details-sec {
        h2 {font-size: 26px;}
    }
}
.casestudy-text h5 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}