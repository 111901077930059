footer {
    position: relative;
    z-index: 9;
    background-color: var(--dark-color);
    padding: 70px 0;
    margin-top: -1px;
    p, a, li, h3,h5, h4,span {color: var(--white-color);}
    p {font-size: 14px;}
    h5 {font-size: 20px;}
}


.websitecarbon {
    margin: 30px 0 5px 0;
    position: relative;
    input.form-control {border-radius: 5px; border: 2px solid #00AA58; font-size: 14px; color: #666666;padding-right: 150px;}
    button.btn-success {
        position: absolute;
        right: 0;
        top: 0;
        border-radius: 0 5px 5px 0;
        background-color: #00AA58;
        color: #000000;
        font-size: 14px;
        border-color: #00AA58;
        font-weight: 700;
    }
}

.footer-links {
    ul {
        li {
            a {
                color: var(--white-color); font-weight: 500; text-decoration: none;
                padding:7px 0px; font-size: 14px; display: block;
                &:hover {color: var(--secondary-color);}
            }
        }
    }
}

.footer-bottom {
    color: rgba(255 255 255 / 80%); font-size: 14px;
    border-top: 1px solid #00AA58;
    padding-top: 30px;
    margin-top:35px;
    p, a {color: rgba(255 255 255 / 80%); font-size: 14px;}
}

.footerlinks-bottom {
    display: flex;
    justify-content: center;
    a {
        color:rgba(255 255 255 / 80%);
        text-decoration: none;
        padding: 0 20px;
        font-weight: 500;
        &:hover {color: var(--secondary-color);}
    }
}

.social-links {
    display: flex;
    justify-content: end;
    a {
        font-size: 20px; width: 20px; height: 20px; margin-left:20px;
        &:hover {color: var(--secondary-color);}
    }
}

.certified-logos {
    img {
        height: 80px;
        margin-right: 15px;
    }
}

@media (max-width:1023px) {
    .footer-links {
        text-align: left;
        ul {
            display: block;
            li a {padding: 6px 0px; display: block;}
        }
    }
    .footerlinks-bottom {
        display: block;
        a {padding: 6px 0px; display: block;}
    }

    .social-links {
        justify-content: start;
        margin-top: 14px;
        a {margin-left: inherit; margin-right: 20px;}
    }
    .footer-links ul {justify-content: start;}
}