.bookdemo-cont {
    padding-top: 0px;
    .address-block {
        background-color: var(--base-color);
        height: 100%;
        h5 {font-size: 16px; font-weight: 600; text-transform: uppercase;}
        p {font-size: 14px;}
        h5, p {color: var(--white-color);}
    }
}

.bookdemo-con {padding-top: 180px;}

.contact-form {
    background-color: #F8F6F4;
    padding:35px 20px;
}

.react-tel-input .form-control {
    padding: .375rem .75rem .375rem 52px;
    width: 100%;
    font-size: 1rem;
    border: 1px solid #dee2e6;
    border-radius: 0.375rem;
    &:focus {
        border-color: rgb(33 226 198 / 26%);
        outline: 0;
        box-shadow: 0 0 0 0.25rem rgb(33 226 191 / 10%);
    }
  }

@media (max-width:767px) {
    .bookdemo-con {padding-top: 100px;}
}
[type="submit"]:disabled{
    background-color: #ccc;
    border: 1px solid #ccc;
    color: #fff;
    cursor: not-allowed;
}