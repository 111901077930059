.headertop {
    background-color: #01283E;
    font-size: 11px;
    font-weight: 600;
    color: rgba(255 255 255 / 50%);
    text-align: center;
    padding: 5px 20px;
    text-transform: uppercase;
}

header {
    webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;

    &.fixed-top {top: 10px;}
    .nav-link {color: var(--white-color); font-size: 18px; font-weight: 500;margin-left: 20px;margin-right: 20px;padding-left: 0 !important;padding-right: 0 !important; position: relative;

        &::before {content: ""; height: 2px; position: absolute; left: 0; bottom: 0; background-color: var(--secondary-color); -webkit-transition: max-width 0.5s; transition: max-width 0.5s; max-width: 0;}
        &:hover {color: var(--secondary-color);}
        &:hover:before, &.active:before { max-width: 100%; width: 100%;}
        &.active {color: var(--secondary-color) !important;}
    }

        .nav-item.dropdown {position: relative;}
        .nav-item.dropdown::before {
            content: ""; height: 2px; position: absolute; left: 0; bottom: 0; background-color: var(--secondary-color); -webkit-transition: max-width 0.5s; transition: max-width 0.5s; max-width: 0;
        }

        .nav-item.dropdown { 
            &.active:before, &:hover:before {max-width: 100%; width: 100%;}
            &.active, &:hover {
                .dropdown-toggle {
                    color: var(--secondary-color);
                    &::before {display: none;}
                }
            }
            .dropdown-toggle {
                &::before {display: none;}
            }
        }

    &.fixed-top {
        &.headerdark {
            background-color: var(--dark-color);
            top: 10px;
            border-radius: 100px;
            padding-left: 0px;
            padding-right: 0px;
            max-width: 1280px;
            margin: auto;
            width: 100%;
            .App-logo {
                height: 6vmin;
            }
        }
    }
    nav.bg-dark {background: transparent !important;}
    .container {position: relative;}

    .navbar-nav .dropdown-menu {
        background:var(--white-color);
        border: 0;
    }

    .dropdown {
        margin-left: 20px;
        margin-right: 20px;
        padding-left: 0 !important;
        padding-right: 0 !important;
        .dropdown-toggle.nav-link {
            margin-left: 5px;
            margin-right: 5px;
            color: var(--white-color);
        }

        .nav-link {
            color: #4B4B4B; 
            font-size: 17px; 
            font-weight: 500;
            margin-left: 0;
            margin-right: 0;
            span {font-size: 13px; color: #9B9B9B; font-weight: 400; display: block;}
            // &::before {display: none;}
            &:hover {color: var(--secondary-color);}
        }
        h5 {font-size: 14px; text-transform: uppercase; color: #666666;margin-top: 15px; margin-bottom: 15px;font-weight: 400;}
    }

    .dropdown-menu {
        .eventsNav {
            .nav-link {
                padding-top: 15px;
                padding-bottom: 15px;
                &:before {content: none;}
            }
        }
    }
}

.header-mobile-container {
    header.fixed-top {
        background-color: var(--dark-color);
    }
}
.navbar-toggler:focus {outline: none; box-shadow: none;}
button.navbar-toggler {
    border: 0;
    padding: 0 5px;
    .navbar-toggler-icon {
        width: 2em;
        height: 2em;
    }
}


.bookdemo-container {
    button.navbar-toggler .navbar-toggler-icon {
        background: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27rgba%280, 0, 0, 0.55%29%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e");
    }

    .App-logo.logo-light {display: none;}
    header {
        .nav-link {
            color: var(--dark-color);
            &:hover {color: var(--secondary-color);}
        }

        .dropdown .dropdown-toggle.nav-link {
            color: var(--dark-color);
            &:hover {color: var(--secondary-color);}
        }

        &.headerdark {
            .nav-link {
                color: var(--white-color);
                &:hover {color: var(--secondary-color);}
            }

            .App-logo.logo-dark {display: none;}
            .App-logo.logo-light {display: block;}

            button.navbar-toggler .navbar-toggler-icon {
                background: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27rgba%28255, 255, 255, 0.55%29%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e");
            }

            .dropdown .dropdown-toggle.nav-link {
                color: var(--white-color);
                &:hover {color: var(--secondary-color);}
            }

            .eventsNav {
                .nav-link {
                    color: var(--dark-color);
                    &:hover {color: var(--secondary-color);}
                }
            }
        }
        
    }

    .header-mobile-container {
        .nav-link {
            color: var(--white-color);
            &:hover {color: var(--secondary-color);}
        }

        .App-logo.logo-dark {display: none;}
        .App-logo.logo-light {display: block;}
        
        button.navbar-toggler .navbar-toggler-icon {
            background: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27rgba%28255, 255, 255, 0.55%29%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e");
        }

        .dropdown .dropdown-toggle {
            .nav-link {
                color: var(--white-color);
                &:hover {color: var(--secondary-color);}
            }
        }
        .eventsNav {
            .nav-link {
                color: var(--dark-color);
                &:hover {color: var(--secondary-color);}
            }
        }

        .dropdown-menu .eventsNav .nav-link {
            padding-top: 10px;
            padding-bottom: 10px;
        }
    }

    
}

.ll-logo-display {
    .App-logo.logo-dark {display: none;}
}


// Mega Menu


  
  #seeMoreLink {
    color: #607d8b;
  }
  
  #seeMoreLink:hover {
    color: black;
    font-weight: 400;
  }
  .dropdown-menu {
    padding: 1em;
  }
  
  .dropdown-item:hover {
    outline: none;
    box-shadow: none;
    background: none;
  }
  .dropdown-item a:hover {
    color: black;
  }
  
  .dropdown-item {
    height: 2em;
    padding-left: 0em;
    .nav-link {
        color: var(--dark-color);
    }
  }
  
  .dropdown-header {
    font-weight: 600;
    padding-left: 0em;
  }
  
  @media only screen and (min-width: 768px) {
    .eventsNav {
      width: 52em;
      padding-bottom: 1em;
      &.smallevnav {
        width: 36em;
      }
    }
    .dropdown-menu[data-bs-popper] {
        left: -100%;
    }

  }

  .planeHeader {
    header {
        background: var(--dark-color);
        top: 10px;
        border-radius: 100px;
        padding-left: 10px;
        padding-right: 10px;
        max-width: 1280px;
        margin: auto;
        width: 100%;
    }
    .App-logo {height: 6vmin;}

    .fixed-top {
        &.headerdark {
            .App-logo {
                height: 5vmin;
            }
        }
    }
    .Footer-container {
        .App-logo {height: 8vmin;}
    }
  }

 

// End


@media (max-width:1023px) {
    header .dropdown {
        margin-left: 0px;
        margin-right: 0px;
    }
    .headerRight {
        position: absolute;
        right: 12px;
        top: 5px;
    }
    .headerdark {
        .headerRight {top: 0;}
    }
    // .eventsNav {
    //     .col-md-6 {padding-left: 0px; padding-right: 0px;}
    // }
}

.nav-item.dropdown:hover .dropdown-menu {
    display: block;
    box-shadow:0 0 10px rgb(0 0 0 / 10%);
}

.dropdown-menu {
    transition: bottom 0.5s ease;
}


@media (max-width:991px) {
    .headertop {font-size: 10px;}
    .planeHeader header {
        border-radius: 0;
        padding-left: 0;
        padding-right: 0;
    }
    header.fixed-top {
        top: 0px;

        .App-logo {
            height: 14vmin;
            pointer-events: none;
        }
        .container {
            padding-left: 15px;
            padding-right: 15px;
        }

        &.headerdark {
            border-radius: 0;
            top: 0;
            .container {
                padding-left: 5px;
                padding-right: 10px;
            }
            .App-logo {
                height: 6vmin;
            }
        }

        
    }

    .navbar-collapse {
        button.navbar-toggler {
            position: absolute;
            right: 15px;
            padding: 0;
            z-index: 9;
        }
        button.navbar-toggler .navbar-toggler-icon {
            background: url("../../../public/assets/img/close-menu.svg") no-repeat center;
        }
    }

    header {
        .collapse:not(.show) {
            display: inherit;
            left: -100%;
        }
        .collapse.show {
            left: 0;
        }
        .navbar-collapse {
            padding-top: 15px;
            padding-bottom: 15px;
            position: fixed;
            top: 64px;
            left: 0;
            width: 100%;
            height: 91vh;
            background: var(--dark-color);
            z-index: 9;
            overflow: auto;
            padding-left: 20px;
            padding-right: 20px;
            transition: left 0.5s ease;
            .nav-link {
                margin-left: 5px;
                margin-right: 5px;
            }
        }

        // .navbar-collapse .eventsNav .nav-link {
        //     margin-left: 10px;
        //     margin-right: 10px;
        // }
    }

    header.headerdark .navbar-collapse {
        top: 60px;
    }

    .inner-hero {
        min-height: 92vh;
    }

    header.fixed-top .App-logo {height: 9vmin;}
    .planeHeader .fixed-top.headerdark .App-logo {height: 7vmin;}
  }

  @media (max-width:767px) {
    header {
        .btn-primary {font-size: 14px;margin-right: 5px;}
    }
    header.fixed-top .App-logo {height: 11vmin;}
    header.fixed-top.headerdark .App-logo{height: 9vmin;}
    .planeHeader .fixed-top.headerdark .App-logo {height: 9vmin;}
    button.navbar-toggler {
        border: 0;
        padding: 0 0 0 5px;
    }
    
  }