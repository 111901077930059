@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight:400;
  line-height: 1.6;
  font-size: 16px;
}

main {overflow: hidden;}



:root {
  --base-color: #024064;
  --secondary-color:#21E2BD;
  --white-color: #ffffff;
  --heading-color:#002A16;
  --body-text-color:#4B4B4B;
  --green-color:#9ED983;
  --dark-color:#01283E;
}

.bg-secondary {background-color: #21E2BD !important;}

@media (min-width: 1200px) {
.container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1280px;
}
}

a {padding-left: 0px; padding-right: 0px; color: var(--secondary-color); text-decoration: none; font-weight: 700; }
a:hover {color: var(--base-color);}

.home-container {background-color:var(--base-color);}

.section-padd {padding-top: 100px; padding-bottom: 100px;}
.section-padd-top {padding-top: 100px;}
.section-padd-bottom {padding-bottom: 100px;}

.App-logo {
  height: 8vmin;
  pointer-events: none;
  webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

h1,h2,h3,h4,h5,h6 {font-weight: 700;}
h1 {font-size: 54px;}
h2 {font-size: 46px;}
h3 {font-size: 38px;}
h4 {font-size: 28px;}
h5 {font-size: 22px;}

h6 {
  color: var(--secondary-color);
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
}
.form-control:focus {
  border-color: rgb(33 226 198 / 26%);
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(33 226 191 / 10%);
}

input::-webkit-input-placeholder { color: #ccc; font-size: 13px;}
input:-moz-placeholder {color: #ccc; font-size: 13px; opacity:  1;}
input::-moz-placeholder {color: #ccc; font-size: 13px;opacity:  1;}
input:-ms-input-placeholder {color: #ccc; font-size: 13px;}
input::-ms-input-placeholder {color: #ccc; font-size: 13px;}
input::placeholder {color: #ccc; font-size: 13px;}

.form-label {
  font-size: 14px;
  font-weight: 500;
  sup {color: #ff0000;font-size: 14px;}
}

.btn-primary {
  background-color: var(--secondary-color); color: var(--base-color); font-weight: 600; border-radius: 50rem; border-color: var(--secondary-color);
}

.btn-primary:hover, .btn-primary:active, .btn-primary:focus .btn:first-child:active, :not(.btn-check)+.btn:active {background-color: transparent; color: var(--secondary-color);border-color: var(--secondary-color);}

.btn-outline-primary {border-color: var(--secondary-color);color: var(--secondary-color);border-radius: 50rem;font-weight: 600;}
.btn-outline-primary:hover {background-color: var(--secondary-color);border-color: var(--secondary-color); color: var(--base-color);}

.btn-link {font-weight: 500; padding-left: 0px; padding-right: 0px; color: var(--secondary-color); text-decoration: none; }

.btn-link:hover {color: var(--base-color);}

.icon-heading h4 {display: flex; align-items: center;}
.icon-heading h4 .circle-img span {
  /* background-color: rgba(158 217 131 / 28%);  */
  width: 90px; 
  height: 90px; 
  border-radius: 50%; 
  text-align: center; 
  line-height: 90px; 
  margin-right: 20px;
  display: block;}
/* .icon-heading h4 .circle-img span img {width: 50px;} */


.tags-green {border: 1px solid var(--secondary-color); color: var(--secondary-color); font-weight: 700; font-size: 12px; padding: 7px 15px; border-radius: 50rem; display: inline-block; text-transform: uppercase;}

/* Inner pages */

.inner-hero {
  min-height: 100vh;
  padding-top: 50px;
  background: rgb(59,179,193);
  background: linear-gradient(0deg, rgba(59,179,193,1) 0%, rgba(1,64,100,1) 100%);
  display: flex;
  align-items: center;
  overflow: hidden;
  border: 1px solid #8faec0;
}

.inner-hero h1, .inner-hero p {color: var(--white-color);}
.inner-hero p {font-size: 20px; font-weight: 500;}

.bg-grad {
  background: rgb(59,179,193);
  background: linear-gradient(0deg, rgba(59,179,193,1) 0%, rgba(1,64,100,1) 100%);
}

.CTA-section h2, .CTA-section p, .CTA-section li, .CTA-section span {color: var(--white-color);}

.products-container .clients-section {
  background-color: #002A16;
  border-radius: 0;
}

/* End */

.liststyled li {
  padding: 5px 0;
}
.liststyled {color: rgba(0, 0, 0, 0.8);}
.terms-con .container {max-width: 1024px;}
.terms-con h5 {font-size: 18px; margin-top: 50px;}
.terms-con a {color: var(--secondary-color); text-decoration: none; font-weight: 500;}
.terms-con a:hover {color: var(--base-color);}

.list-roman {
  list-style: decimal-leading-zero;
}

.liststyel-decimal {
  list-style-type: disc;
}

.glossary-container .inner-hero {min-height: 54vh;}

.clearsearch {
  font-size: 12px; 
  text-transform: uppercase;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
}
.clearsearch svg {font-size: 18px; margin-left: 5px;}
.glossary-search .form-control {padding-left: 30px;}
.glossary-search h6 {color: var(--base-color);}
.glossary-search .searchicon {position: absolute; top: 3px; left:10px;}
.glossary-sorting {display: flex; justify-content: space-between; align-items: center;}
.noofglossary {font-size: 14px;color: #777;}
.noofglossary .active-glo {color:var(--secondary-color); font-weight: 700;}
.noofglossary .total-glo {color:var(--dark-color) ; font-weight: 700;}

.glossary-sorting .nameSOrt {text-decoration: none; font-size: 14px; color: #666; font-weight: 500;}
.glossary-sorting .nameSOrt:hover {color: var(--secondary-color);}

.glossary-box {margin-top: 30px; border-bottom:1px solid #ddd; padding-bottom: 30px;}
.text-oppor p:last-child {margin-bottom: 0px;}
.read-glossari {margin-top: 20px;}
.read-glossari .btn-link {font-size: 15px; font-weight: 500; display: inline-flex; align-items: center;}
.read-glossari .btn-link svg {font-size: 20px; margin-right: 5px;}

.sourcetxt a {color: #777; font-size: 12px; text-decoration: none;}
.categoryLists {display: flex;}

.categories .badge {border-radius: 40px; font-weight: 400; font-size: 14px;color: var(--base-color);padding: 6px 12px; margin-left: 10px;}
.categories .badge.bg-secondary {background-color: rgb(33 226 189 / 17%) !important;}
.categories .badge.bg-secondary:hover {background-color: rgb(33 226 189 / 50%) !important;}

.selectedFilter .badge {border-radius: 40px; padding: 5px 10px 5px 15px; font-weight: 500; margin-right: 10px;}
.selectedFilter .badge svg {margin-left: 7px; font-size: 16px;}
.articleBox.card {border: 0px; box-shadow: 0px;}
.articleBox.card .card-body {padding: 0px;}
.articleBox.card .card-body .card-title {
  font-size: 20px; 
  font-weight: 700; 
  display: block;
  line-height: 1.4; 
  margin-top: 10px;
}

.selectedFilter .badge.bg-secondary {background-color: var(--secondary-color) !important; color: #fff;}

.articleBox.card .card-body .card-title:hover, .articleBox.card:hover .card-title {color: var(--secondary-color);}

.articleBox.card .card-body .card-title, .articleBox.card .card-body .card-text {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.badge.bg-secondary {
  border-radius: 40px;
  font-weight: 400;
  font-size: 12px;
  color: var(--base-color);
  padding: 6px 12px;
  background-color: rgb(33 226 189 / 17%) !important;
}

.badge {
  border-radius: 40px;
  font-weight: 400;
  font-size: 12px;
  color: var(--base-color);
  padding: 6px 12px;
}

.imgblogdetails img {max-width: 100%;}

.subtangs {display: flex; align-items: center; margin-top: 10px;}
.subtangs .badge.bg-light {background-color: #ccc !important; color: #000; margin-right: 5px;}
.blogowner {display: flex; align-items: center;}
.bownerimg {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
}
.bownerimg img {width: 100%;}

.blogdetails-content h4, .blogdetails-content h5, .blogdetails-content h3 {margin-top: 30px; font-weight: 600;}
.blogdetails-content ul li {padding: 7px 0;}
.rm-bloglist-img {border-radius: 10px; overflow: hidden; height: 150px;}
.rm-bloglist-img img {width: 100%;}
.rm-blog-txt h5 {font-size: 18px;}
.rm-blog-txt h5 a {
  color: var(--base-color);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.rm-blog-txt h5 a:hover {color: var(--secondary-color);}

.blogimg {overflow: hidden;height: 230px;}
.blogimg img {transition: transform .2s; /* Animation */}
.articleBox:hover .blogimg img {transform: scale(1.05);}

.cta-section {
  background: url(../public/assets/img/cta-bg.png) no-repeat;
  background-size: 100% 100%;
}
.cta-section h2, .cta-section p {color: #fff;}

.cta-section .container {
  max-width: 480px;
}

.page404 .container {max-width: 800px;}


@media (max-width:991px) {
  .glossary-container .about-hero-txt {padding: 40px 0;}
  h1 {
    font-size: 26px;
  }
  h2 {
    font-size: 24px;
  }
  h5 {
    font-size: 19px;
  }
  h4 {
    font-size: 22px;
  }
  .section-padd-top {
    padding-top: 60px;
  }
  .section-padd-bottom {
    padding-bottom: 60px;
  }
  .container {
    padding-left: 20px;
    padding-right: 20px;
  }
  .section-padd {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .inner-hero p {
      font-size: 16px;
    }

    .categoryLists {
      display: block;
  }
  .categories .badge.bg-secondary {margin-left: 0px; margin-right: 10px; margin-top: 10px;}
  .categories {
    display: flex;
    overflow: auto;
    padding-bottom: 10px;
  }
  .rm-blog-txt h5 {
    font-size: 16px;
}

.cta-section {
  background-size: auto 100%;
  background-position:center;
}

.Footer-container .App-logo {height: 12vmin;}

.relativeArticle {padding-bottom: 130px !important;}

.relativeArticle .owl-carousel .owl-nav button.owl-prev span, .relativeArticle .owl-carousel .owl-nav button.owl-next span {
  border: 1px solid #01283e; color:#01283e;
}

}