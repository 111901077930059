.fixedbg {
    overflow: hidden;
    position: fixed;
    bottom: -80%;
    left: 0;
    right: 0;
    img {width: 100%;}
}

.bc-home {
    position: relative; z-index: 1;padding-top: 70px;
    color: var(--white-color);
    h1,h2,h3,h4,p,li,span {color: var(--white-color);}

    h6 {color: var(--secondary-color); text-transform: uppercase; font-weight: 600; font-size: 12px;}
}

.hero-section {
    height: 100vh;
    display: flex;
    align-items: center;
    p {font-size: 18px;}
}

.journey-section {

    h6 {font-size: 13px; text-transform: uppercase;color: #fff;}
    p {font-size: 13px; font-weight: 500;min-height: 60px;}

    .journey-box {
      position: relative;
      overflow: hidden;
        .btn-link {
            font-size: 13px; color: #fff; padding: 0;
            &:hover {color:var(--secondary-color);}
        }
    }
}
.journey-front {
  h6 {padding: 0 12px;margin-bottom: 15px;}
  img {width: 100%;}
}

.journey-box {
  cursor: pointer;
  .servicesHover {
    background: #629dae;
    padding: 10px 12px;
    border-radius: 10px;
    position: absolute;
    height: 100%;
    left: 0%;
    bottom: -100%;
    overflow: hidden;
    transition: bottom 0.5s ease;
  }
  &:hover {
    .servicesHover {bottom: 0;}
  }
}

.clients-section {
    background-color: rgb(0 42 22 / 6%);
    border-radius: 50rem;
    padding: 10px 40px;
    display: flex;
    justify-content: center;
    li {
        margin: 0 30px;
        img {height: 40px;}
    }
}

.trustedby {
    h6 {font-size: 12px; text-transform: uppercase; font-weight: 600; margin-bottom: 10px;}
}
// .opportunity-section {
//   min-height:100vh;
// }
.oppor-left {
  position: -webkit-sticky; 
  position:sticky;
  top:120px;
  z-index: 1020;
}


.opportunity-box {
    margin-top: 100px;
    h5 {
        display: flex;
        align-items: center;
        span {width: 53px; height: 35px; margin-right: 20px; border-radius: 16px; text-align: center; line-height: 35px; color: #fff; background-color: rgba(255 255 255 / 26%); font-size: 13px;}
    }
}

.text-oppor {font-size: 18px;}

.grid-container{
    columns: 3;
    column-gap: 10px;
    .col-grid {
        background:rgba(0 0 0 / 15%);
        margin-bottom: 10px;
        break-inside: avoid;
        padding: 0;
        border-radius: 15px;
        padding: 20px;
    }
}

.clients-box {
    .clients-profile-tag {background-color: #DFDFDF; border-radius: 15px; padding: 6px 20px; text-transform: uppercase; font-size: 12px; font-weight: 700; color: #666666;}

    .btn-outline-primary {
        border-color: var(--white-color); color: var(--white-color);
        &:hover {border-color: var(--secondary-color); color: var(--base-color);}
    }
}

.rources-box {
    .rources-icon {
        background-color: rgba(255 255 255 / 34%);
        display: flex;
        justify-content: center;
        align-items: center;
        height: 140px;
        border-radius: 10px;
    }
}

// Logo Slider

// section.trustedby {
//     max-width: 740px;
//     margin: 0 auto;
//     width: 100%;
// }

.slider {
    height: 60px;
    position: relative;
    width: 100%;
    display: grid;
    place-items: center;
    overflow: hidden;
  }
  .slider::before{
    left:0;
    top:0;
  }
  .slider::after{
    right:0;
    top:0;
    transform:rotateZ(180deg);
  }
  
  /*  IMPORTANT CODE BELOW */
  
  .slide-track {
    width: calc(150px * 13);
    display: flex;
    animation: scroll 20s linear infinite;
    justify-content: space-between;
  }
  
  .slide-track2 {
    width: calc(150px * 15);
    display: flex;
    animation: scroll2 15s linear infinite;
    justify-content: space-between;
  }
  
  .slide {
    width: 150px;
    height: 60px;
    display: grid;
    place-items: center;
    transition:0.5s;
    cursor:pointer;
    img {width: 80px;}
  }

  @keyframes scroll {
    0% {
      transform: translateX(0px);
    }
    100% {
      transform: translateX(calc(-150px * 10));
    }
  }
  
  @keyframes scroll2 {
    0% {
      transform: translateX(0px);
    }
    100% {
      transform: translateX(calc(-150px * 5));
    }
  }
  
  @media screen and (max-width: 768px) {
    .slide-track {
      width: calc(80px * 20);
    }
  
    .slide-track2 {
      width: calc(80px * 15);
    }
  
    .slide {
      width: 80px;
    }
  
    @keyframes scroll {
      0% {
        transform: translateX(0px);
      }
      100% {
        transform: translateX(calc(-80px * 10));
      }
    }
  
    @keyframes scroll2 {
      0% {
        transform: translateX(0px);
      }
      100% {
        transform: translateX(calc(-80px * 5));
      }
    }
  }

  .clients-box {
    background: rgba(0, 0, 0, 0.15);
    border-radius: 15px;
    padding: 20px;
    .client-box-head {
      display: flex;
      height: 100%;
      align-items: center;
      background: #9fd983;
      border-radius: 10px;
      justify-content: center;
      padding: 0;
      overflow: hidden;
      height: 170px;
  }
  .clients-content {
    p {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
  }

  .opportunity-slider {
    .clients-box {
      .client-box-head {
        background:#17bd9d;
        font-size: 36px;
        font-weight: 400;
        min-height: 158px;
      }
    }
  }

  .recources-box {
    a {
      background: rgba(0, 0, 0, 0.15);
      border-radius: 15px;
      padding: 10px 15px;
      display: block;
      text-decoration: none;
      .re-text {
        h5 {
          font-weight: 500; 
          font-size: 18px; 
          text-decoration: none; 
          color: #fff;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
          span.badge {
            text-transform: uppercase;
            font-weight: 700;
            padding:6px 10px;
            background: #DFDFDF !important;
            color:#666666;
            border-radius: 40px;
            font-size: 11px;
        }
      }
      &:hover {background:rgb(33 226 189 / 45%);}
    }
    
  }

  .re-img {
    height: 180px;
    overflow: hidden;
}

  .clients-slider, .recources-slider, .opportunity-slider {
    .owl-nav {
      position: absolute;
      right: 0;
      button {
        margin-left: 10px;
        margin-top: 20px;
        span {
          display: block;
          width: 50px;
          height: 50px;
          border: 1px solid var(--white-color);
          text-align: center;
          line-height: 40px;
          font-size: 40px;
          border-radius: 50%;
          color: var(--white-color);
        }
      }
    }
    .owl-dots {
      position: absolute;
      left: 0;
      button {
        margin-top: 20px;
        margin-right: 5px;
          span {
            display: block;
            width: 15px;
            height: 15px;
            background: #01253a;
            border-radius: 50%;

          }
        &.active {
          span {background-color: var(--white-color);}
        }
      }
    }
  }

// End

.cta-home {margin-top: 100px;}

@media (max-width:1023px) {
    .oppor-left {position: static;}
    .grid-container {columns: 2;}
    .clients-section {flex-wrap: wrap;}
    .journey-box {height: 100%;}
    .opportunity-box {
      margin-top: 60px;
  }
  .clients-box .client-box-head {
    height: 180px;
  }
  .cta-home {margin-top:80px;}

  .journey-section {
    p {
      font-size: 12px;
      font-weight: 400;
      min-height: 60px;
      margin-bottom: 0;
      line-height: 1.4;
  }
  h6 {font-size: 12px; padding: 0;}
  }

  .hero-section {height: 76vh;}

  .bc-home {
    .hero-section {
        height: auto;
        padding: 20px 0 50px;
        text-align: center;
    }
  }
}

@media (max-width:767px) {
  .grid-container {columns: 1;}
  .fixedbg {bottom: -20%;}
  .clients-content {
    margin-top: 20px;
}
.clients-slider .owl-nav button, .recources-slider .owl-nav button {margin-top: 5px;}
.clients-slider .owl-nav, .recources-slider .owl-nav {
  right: 0;
    left: 0;
    text-align: center;
}

.recources-slider .owl-nav button {
  margin-top: 20px;
}
}