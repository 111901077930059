.About-container {overflow-x: hidden;}
.about-CTA {
    background: rgb(59,179,193);
    background: linear-gradient(0deg, rgba(59,179,193,1) 0%, rgba(1,64,100,1) 100%);

    .cta-bg {
        background: url(../../../public/assets/img/ready-to-make.png) no-repeat 150% bottom;
        background-size: 70%;
        min-height: 80vh;
        display: flex;
        align-items: center;
        overflow: hidden;

        h2, p {color: var(--white-color);}
    }
}

.about-hero-txt {
    padding: 100px 0;
}

.about-image {max-width: 80%;}

.story-block {
    h6 {font-size: 22px; text-transform: none; color: #9ED983;}
    h5 {font-size: 18px;}
}

.team-middle {margin-top: -30px;}

.team-info {
	position: relative;
	background: #E5FBF0;
    padding: 20px 10px;
    text-align: center;
    h6 {font-size: 17px; color: #00AA58; margin-bottom: 5px; text-transform: none;font-weight: 700;}
    p {font-size: 14px;}
}
.clients-slider.team-members {
    .owl-dots button span {background-color: #b7b7b7;}
    .owl-dots button.active span {background-color:var(--base-color);}
    .owl-nav button span {
        border: 1px solid var(--base-color);
        color: var(--base-color);
    }
}

.team-member {
    max-height: 215px;
    overflow: hidden;
    background-color: #eee;
    img {max-width: 100%;}
}

.stackholders {
    .team-member {max-height: 300px;}
}

.trans-bg {
    background: rgb(255,255,255);
    background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(58 177 191 / 14%) 100%);
}

.sustain-logo-box {
    text-align: center;
    margin-bottom: 20px;
    img {max-height: 70px; max-width: 100%;}
}

@media (max-width:991px) {
    .about-image {
        max-width: 64%;
    }
    .team-middle {
        margin-top: 0;
    }
    .sustain-logo-box {
        img {max-height: 50px;}
    }

    .about-CTA {
        .cta-bg {
            background-size: 90%;
            background-position: 260% bottom;
            min-height: 54vh;
        }
    }
    .our-sustainbility {padding-top: 60px;}
    .about-hero-txt {padding: 50px 0; text-align: center;}
}